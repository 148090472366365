<template>
  <div ref="fiscalPeriodSelect" class="fpl-container uds-dropdown-list uds-shadow-s">
    <template v-for="fiscalPeriod in fiscalPeriods" :key="fiscalPeriod.id">
      <div class="fpl-item uds-dropdown-line" @click.stop="changePeriod(fiscalPeriod)">
        <span class="fpl-name uds-dropdown-item">
          {{ fiscalPeriodToString(fiscalPeriod) }}
        </span>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { IExercice } from "@silexpert/core";

const exerciceStore = useExerciceStore();
const dayjs = useDayjs();

const emit = defineEmits<{
  (
    e: "change-period",
    dates: { dateStart: string | null; dateEnd: string | null; idExercice: number | null },
  ): void;
  (e: "close"): void;
}>();

const fiscalPeriods = computed<IExercice[]>(() => exerciceStore.fiscalPeriods);

function fiscalPeriodToString({ startDate, endDate }: IExercice) {
  const start = dayjs(startDate).format("LL");
  const end = dayjs(endDate).format("LL");
  return `Du ${start} au ${end}`;
}

function changePeriod(period: IExercice) {
  emit("change-period", {
    // @ts-expect-error wrong backend type (should be string)
    dateStart: period.startDate,
    // @ts-expect-error
    dateEnd: period.endDate,
    idExercice: period.id ?? null,
  });
}
</script>
